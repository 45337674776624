/* eslint-disable @typescript-eslint/consistent-type-definitions */
import {Column} from 'react-table';

export enum Status {
  DEMO = 'demo',
  DRAFT = 'draft',
  LIVE = 'live',
  FINISHED = 'finished',
}

export enum Difficulty {
  hard = 'hard',
  easy = 'easy',
}

export enum StudyGroupType {
  BRAND_STUDY = 'Brand Study',
  DATA_COLLECTION = 'Data Collection',
}

export enum ArchiveStatus {
  archived = 'archived',
  unarchived = 'unarchived',
}

export enum CreativeType {
  HT = 'HT',
  SS = 'SS',
  DB = 'DB',
  TT = 'TT',
  HH = 'HH',
  HB = 'HB',
  SW = 'SW',
}

export enum Continents {
  Oceania = 'Oceania',
  Europe = 'Europe',
  NorthAmerica = 'North America',
  Asia = 'Asia',
}

export enum Environment {
  'PRODUCTION' = 'production',
  'DEVELOPMENT' = 'development',
  'STAGING' = 'staging',
}

export enum Feature {
  'REPORTING' = 'REPORTING',
}

export enum AdCampaignTypes {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export enum SnackbarType {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

export enum PaneCardActionName {
  ADD = 'add',
  UPLOAD_CSV = 'uploadCsv',
}

export enum AdFormat {
  PXYZ_SUPER_SKIN_V2 = 'pxyz-super-skin-v2',
  PXYZ_HANG_TIME = 'pxyz-hang-time',
  PXYZ_HOVER_BOARD = 'pxyz-hover-board',
  PXYZ_HANG_TIME_DESKTOP_V2 = 'pxyz-hang-time-desktop-v2',
  PXYZ_VIDEO_SWITCH = 'pxyz-video-switch',
  PXYZ_HANG_TIME_JNR = 'pxyz-hang-time-jnr',
  GG_IN_SCREEN = 'gg-in-screen',
  GG_IN_SCREEN_FRAME = 'gg-in-screen-frame',
  GG_IN_SCREEN_EXPANDABLE = 'gg-in-screen-expandable',
  GG_IN_SCREEN_EXPANDABLE_VIDEO = 'gg-in-screen-expandable-video',
  GG_IN_SCREEN_EXPANDABLE_CORNER = 'gg-in-screen-expandable-corner',
  GG_IN_IMAGE = 'gg-in-image',
  GG_IN_IMAGE_EXPANDABLE = 'gg-in-image-expandable',
  GG_IN_IMAGE_CANVAS = 'gg-in-image-canvas',
  GG_OUTSTREAM_IN_ARTICLE_VIDEO = 'gg-outstream-in-article-video',
  JP_MOBILE_SCROLLER = 'jp-mobile-scroller',
  JP_MOBILE_SKIN = 'jp-mobile-skin',
  JP_DESKTOP_SKIN = 'jp-desktop-skin',
  STANDARD = 'standard',
}

export interface AdFormatSize {
  width: number | null;
  height: number | null;
}

export type FeatureSet = {[K in Feature]: boolean};
export type FeatureConfig = {[Key in Environment]: FeatureSet};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TListItem {}

export interface MenuItem {
  name: string;
  icon: React.ReactNode;
  path: string;
  component: React.ReactNode;
  root: string;
}

export interface DomainBreakdown {
  domain: string;
  percentage: number;
  count: number;
}

export interface FeedReport {
  isValid: boolean;
  validity: {
    isMaxShareValid: boolean;
    isValidLength: boolean;
    isValidTotals: boolean;
  };
  assumedMaxShare: number;
  feedLength: number;
  largestMaxSharePercentage: number;
  smallestMaxSharePercentage: number;
  perDomainBreakdown: DomainBreakdown[];
  continent?: string;
}

export interface DropdownOption {
  label: string | number;
  value: string | number;
}

export interface FieldElement {
  isEditing?: any;
  creatives?: any;
  readOnly?: boolean;
  required?: boolean;
  isDisabled?: boolean;
  key: string;
  label: string;
  helperText?: string;
  type: string;
  options?: DropdownOption[];
  min?: number;
  max?: number;
  step?: number;
  multiline?: boolean;
  ref?: (ref: React.RefObject<HTMLInputElement>) => void;
  refElement?: React.RefObject<HTMLInputElement>;
  onChangeEvent?: (event: any) => any;
}

export interface Schedule {
  attemptDeadline: {
    nanos: number;
    seconds: string;
  };
  lastAttemptTime: {
    nanos: number;
    seconds: string;
  };
  description: string;
  name: string;
  state: string;
  target: string;
  timeZone: string;
  scheduleTime: {
    nanos: number;
    seconds: string;
  };
  status: {
    details: number[];
    code: number;
    message: string;
  };
}

export interface CloudFunctionStatus {
  articleFeedCacheRefresh?: {
    message?: string;
    status?: boolean;
    startedAt?: firebase.firestore.FieldValue;
    updatedAt?: firebase.firestore.FieldValue;
    finishedAt?: firebase.firestore.FieldValue;
  };
}

export interface Platform {
  name: string;
  conversionUrl?: string;
  id?: string;
}

export interface StudyGroupByBrand {
  [key: string]: {
    id: string;
    name: string;
    pxyzCampaignId: string;
    duration: string;
    difficulty: string;
    archived: string;
    feedConfigId: string;
  }[];
}

export interface Campaign {
  [key: string]: Object[];
}

export interface FeedConfig {
  name: string;
  id: string;
  length: number;
  shuffle: boolean;
  whitelist: string[];
  blacklist: string[];
  includeAdSelectors: boolean;
  includeMeta: boolean;
  prefixTaskTitles: boolean;
  customTaskFrequency: number;
  maxShare: number;
  updatedAt?: firebase.firestore.FieldValue;
  createdAt?: firebase.firestore.FieldValue;
  archived?: boolean;
  isUsed?: boolean;
  schedule?: Schedule;
  cloudFunctionStatus?: CloudFunctionStatus;
}

export type RouteRequestParams = {
  id: string;
  params: any;
};

export interface RouteProps {
  match: RouteRequestParams;
  history: History;
}

export interface RedeemCode {
  id: string;
  completionCode: string;
}

export interface FeedItem {
  title?: string;
  referer: string;
  type: string;
  impressions?: number;
  domain: string;
  image?: string;
  description?: string;
  logo?: string;
}

export interface StudyGroup {
  id?: string;
  type?: string;
  pxyzCampaignId: string;
  brand: string;
  name: string;
  studies: StudyFromStudyGroup[];
  difficulty: string;
  duration: number;
  feedId: string;
  updatedAt?: firebase.firestore.FieldValue;
  createdAt?: firebase.firestore.FieldValue;
  archived?: boolean;
}

export interface StudyFromStudyGroup {
  archived: boolean;
  creatives: CreativeFromStudy[];
  feedConfigId: string;
  id: string;
  name: string;
  platformId: string;
  status: string;
}

export interface CreativeFromStudy {
  format: string;
  id: string;
  name: string;
  sdk: string;
}

export interface Study {
  id?: string;
  name: string;
  brand: string;
  adCampaignId: string;
  studyGroupId: string;
  feedId?: string;
  pxyzCampaignId?: string;
  conversionUrl: string;
  feedConfig: string;
  status: Status;
  surveyUrl: string;
  // this is auto populated by the app, not saved.
  feedConfigName?: string;
  difficulty: string;
  duration: number;
  updatedAt?: firebase.firestore.FieldValue;
  createdAt?: firebase.firestore.FieldValue;
  archived?: boolean;
  isUsed?: boolean;
  studyType: StudyType;
}

export enum DeviceType {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export enum StudyType {
  OLV = 'olv',
  DISPLAY = 'display',
}

export enum DifficultyType {
  EASY = 'easy',
  HARD = 'hard',
}

export interface StudyData {
  name: string;
  deviceType: DeviceType;
  studyType: StudyType;
  duration: number | null;
  feedId: string;
  platformId: string;
  surveyUrl?: string;
  conversionUrl: string;
  difficulty: string;
  status: string;
  brand: string;
}

export interface StudyGroupReportItem {
  id: string;
  studyCompletion: string;
  smStudyCompletion: string;
  uniqueSmCompletedImps: string;
  creatives?: {
    id: string;
    creativeId: string;
    totalImps: number;
  }[];
}

export interface StudyGroupReport {
  success: boolean;
  data?: StudyGroupReportItem[];
  error?: string;
}

export interface StudyGroupDashboardItem {
  archived?: boolean;
  creatives?: CreativeItem[];
  feedConfigId?: string;
  studyId?: string;
  studyName?: string;
  platformId?: string;
  status?: string;
  brand: string;
  pxyzCampaignId: string;
  createdAt?: any;
  difficulty: string;
  duration: number;
  name: string;
  id: string;
}

export interface GlobalBlacklist {
  id?: string;
  wildcard: string;
  archived?: boolean;
  updatedAt?: firebase.firestore.FieldValue;
  createdAt?: firebase.firestore.FieldValue;
}

export interface DataManagerMethods {
  isFieldValid: (field: string, value?: any) => boolean;
  isEditing: () => boolean;
  addToArray: (
    field: string,
    reference: React.RefObject<HTMLInputElement>,
  ) => void;
  deleteArray: (field: string) => void;
  handleDeleteChip: (field: string, value: string) => void;
  handleChange: (field: string) => () => void;
  getCampaign: (campaignId: string) => Promise<boolean>;
  getCreatives: (campaignId: string) => Promise<FirestoreResponse>;
}

export interface iValidity {
  field: string;
  value: any;
  clone: FeedConfig | Study;
  currentField: HTMLInputElement;
}

export interface FirebaseUser {
  displayName?: string;
  email: string;
  emailVerified: boolean;
  isAnonymous: boolean;
  phoneNumber: null | string;
  photoUrl: string;
  refreshToken: string;
  uid: string;
}

interface Profile {
  email: string;
  family_name: string;
  given_name: string;
  granted_scopes: string;
  hd: string;
  i: string;
  locale: string;
  name: string;
  picture: string;
  verified_email: boolean;
}

interface AdditionalUserInfo {
  isNewUser: boolean;
  profile: Profile;
  providerId: string;
}

interface Credential {
  a: string | null;
  accessToken: string;
  idToken: string;
  providerId: string;
  signInMethod: string;
}

export interface AuthResult {
  additionalUserInfo: AdditionalUserInfo;
  credential: Credential;
  operationType: string;
  user: FirebaseUser;
}

export interface CreativeItem {
  name: string;
  id: string;
  type: string | CreativeType; // this can change to CreativeType only when we stop using the mock JSON
  impressions: string;
  atAvg: string;
  modelAtAvg: string;
}

export interface ReportingData {
  group: string;
  name: string;
  id: string;
  urlsActivated: number;
  completes: number;
  averagePagesPerUser: string;
  buyRate: string;
  medianMae: string;
  archived: boolean;
  creative: CreativeItem[];
}

export interface FirestoreResponse {
  success: boolean;
  data?: any;
  error?: string;
}

export interface NewKeys {
  id: string;
  name: string;
}

export interface StudyReportStudyItem {
  completion: number;
  meanMae: number;
  ratePass: number;
  rateFail: number;
  meanPass: number;
  meanFail: number;
}

export interface CreativesPageSample {
  studyId: string;
  creativeId: string;
  totalFixation: number;
  totalImps: number;
}

export interface CreativesModelledAttention {
  creativeId: string;
  modelledAttention: string;
}

export interface AttentionBenchmark {
  format: string;
  time: number;
}

export interface StudyReportItem {
  study: StudyReportStudyItem | null;
  creativesPageSample: CreativesPageSample[];
  creativesModdledAttention: CreativesModelledAttention[];
  lastUpdated: number | null;
}

export interface StudyReport {
  success: boolean;
  data?: StudyReportItem;
  error?: {message: string | null};
}

export type StudyOrStudyGroupOrFeedConfig = Study | StudyGroup | FeedConfig;

export interface CreativeStudyDashBoard {
  format: string;
  id: string;
  name: string;
  thumbnail: string;
}

type DesktopErrorKey =
  | 'name'
  | 'platformId'
  | 'd'
  | 'feedId'
  | 'surveyUrl'
  | 'adCampaignId';

export interface DesktopUrlError {
  error: boolean;
  helperText: string;
}

export interface DesktopUrlErrorState {
  errors: Record<DesktopErrorKey, DesktopUrlError>;
}

export interface DurationConstant {
  name: string;
  description: string;
  value: number;
}

export interface DekstopUrlConstant {
  name: string;
  value: string;
}

export interface SnackBarStateParams {
  isSnackBarOpen: boolean;
  snackBarContent: any;
  snackBarType: SnackbarType;
}

export interface FormError {
  type: string;
  message: string;
}

export interface CustomFeedItem extends TListItem {
  url: string;
  name: string;
  logo?: string;
}

export interface AdCampaignCreative extends TListItem {
  adFormat: string;
  frequencyCapping: number;
  height: number;
  id: string;
  name: string;
  tag: string;
  width: number;
}
export interface Entity {
  id?: string;
  archived?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface GetEntityResponse<T extends Entity> {
  success: boolean;
  data?: T[];
  errors?: any[]; // TODO: need to define better how vp api is sending back errors
}
export interface AdCampaignState extends Entity {
  campaignType?: AdCampaignTypes | '';
  name?: string;
  creatives?: AdCampaignCreative[];
}

export interface CustomFeedState extends Entity {
  feedType?: AdCampaignTypes | '';
  name?: string;
  items?: CustomFeedItem[];
}

export interface FormColumn {
  dropdownOptions?: string[];
  inputProps: {
    key: string;
    label?: string;
    required: boolean;
    type?: 'number' | 'dropdown';
    multiline?: boolean;
    rows?: number;
    disabled?: boolean;
  };
}

export enum ValidationResult {
  PASS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface TagValidation {
  result: ValidationResult;
  message?: string;
}

export interface FormValidation {
  functions: ((item: TListItem | undefined, params?: any) => void)[];
  button: {
    text: string;
  };
}

export interface PanelTableDialogParams {
  formColumns: FormColumn[][];
  title: string;
  subtitle: string;
  formId: string;
  validation?: FormValidation;
}

export interface CardAction extends CardActionWrapper {
  name: string;
}

export interface CardActionUploadCsv extends CardAction {
  errorHandling: VoidFunction;
  mapping: (data: any) => any;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CardActionWrapper {}
export interface PanelTableGeneratorParams<
  T extends Entity,
  CardActionWrapper,
> {
  entity: T;
  setEntity: React.Dispatch<React.SetStateAction<T>>;
  columns: Column<unknown>[];
  listKey: string;
  editKey: string;
  title: string;
  dialog: PanelTableDialogParams;
  cardActions: CardActionWrapper[];
  customListItem?: (listItem: TListItem) => TListItem;
}

export interface CustomAction {
  value: string;
  component: React.FunctionComponent<any>;
  action: () => void;
}
